body {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  font-size: 0.875rem;
  background-color: #4047f0;
}

form {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  margin-bottom: 10px;
  gap: 8px;
}

.row input {
  flex: 1;
}

.column button {
  align-self: flex-end;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

input,
select {
  padding: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  outline: none;
  margin-bottom: 8px;
}

select {
  background-color: #fff;
}

button {
  padding: 8px 12px;
  border: 1px solid #4047f0;
  border-radius: 7px;
  background-color: #4047f0;
  color: #fff;
  cursor: pointer;
}

button.light {
  background-color: #fff;
  color: #4047f0;
}

[disabled] {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

button.tab {
  padding: 8px;
  color: black;
}

button.submit {
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px;
  margin-top: 16px;
}

.container {
  border-radius: 7px;
  padding: 16px;
  border: 2px solid #e9e9e9;
  background-color: #f1f1f1;
}

hr {
  border: none;
  height: 2px;
  color: #e9e9e9;
  background-color: #e9e9e9;
}

@media (max-width: 860px) {
  .container {
    width: 100% !important;
  }
}
